import React, { ChangeEvent, useState, useEffect } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  reqFindTableData,
  reqUpdateTableData,
  reqTransactionData,
  reqTransaction,
} from "../../../requests/warehouse";

// 테이블 목록 정의
const TABLE_NAMES = [
  "admin_badge",
  "admin_user",
  "alarm",
  "badge",
  "barcodes",
  "blog_like",
  "coupon",
  "coupon_logs",
  "lost_shipment_request",
  "membership",
  "migrations",
  "notices",
  "payment_log",
  "point_logs",
  "popup",
  "product_split",
  "question_and_response",
  "reviews",
  "shipping_configuration",
  "ship_order",
  "ship_order_details",
  "ship_product",
  "ship_product_detail",
  "shop_order",
  "shop_product",
  "shop_product_detail",
  "user",
  "user_address",
  "user_confirm",
  "warehouse",
];

// 스타일 정의
const StyledOrderListContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledOrderTitle = styled.h4`
  font-size: 18px;
  margin-bottom: 10px;
  color: #343a40;
`;

const StyledOrderIdList = styled.ul`
  font-size: 14px;
  color: #495057;
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 5px;
  }
`;

// 스타일 정의
const StyledTableContainer = styled.div`
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
`;

const StyledTable = styled.table`
  width: 100%;
  min-width: 600px; /* Ensure table has a minimum width */
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    padding: 12px 8px;
    text-align: center;
    border: 1px solid #dee2e6;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }

  td.editable {
    cursor: pointer;
    background-color: #f1f8ff;

    &:hover {
      background-color: #e2f0ff;
    }
  }

  input {
    width: 100%;
    box-sizing: border-box;
  }
`;

const PaginationWrapper = styled.nav`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }

  .page-item {
    margin: 0 2px;

    .page-link {
      padding: 8px 12px;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      cursor: pointer;
      background-color: #ffffff;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f8f9fa;
      }

      &.active {
        background-color: #007bff;
        color: white;
        border-color: #007bff;
      }
    }
  }
`;

// Responsive styles for the search controls
const SearchControls = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Space between items */

  .control {
    flex: 1 1 200px; /* Grow, shrink, basis */
    min-width: 150px;

    @media (max-width: 576px) {
      flex: 1 1 100%;
    }

    select,
    input {
      width: 100%;
    }
  }
`;

const FindTableDataPage: React.FC = () => {
  const [tableName, setTableName] = useState<string>(TABLE_NAMES[0]);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchKey, setSearchKey] = useState<string>("");
  const [searchWord, setSearchWord] = useState<string>("");
  const [orderColumn, setOrderColumn] = useState<string>("id");
  const [orderWay, setOrderWay] = useState<string>("ASC");
  const [loading, setLoading] = useState<boolean>(false);
  const [editCell, setEditCell] = useState<{
    rowId: number | null;
    column: string | null;
    value: string | number | null;
  }>({ rowId: null, column: null, value: null }); // 현재 수정 중인 셀

  const fetchData = async (page: number = 1) => {
    setLoading(true);
    try {
      const payload = {
        rowCount: 10,
        curPage: page,
        orderColumn,
        orderWay,
        searchKey: searchKey || undefined,
        searchWord: searchWord || undefined,
      };
      const response = await reqFindTableData(tableName, payload);
      setData(response.data.rows);
      setTotal(response.data.totalCount);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching table data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
    };

  const handleSelectChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLSelectElement>) => {
      setter(e.target.value);
    };

  const handleSearch = () => {
    fetchData(1); // 새 검색 시 페이지를 1로 설정
  };

  const handlePageChange = (page: number) => {
    fetchData(page);
  };

  const handleCellClick = (
    rowId: number,
    column: string,
    value: string | number
  ) => {
    setEditCell({ rowId, column, value });
  };

  const handleCellChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditCell((prev) => ({ ...prev, value: e.target.value }));
  };

  const handleCellBlur = async () => {
    if (!editCell.rowId || !editCell.column) return;

    const payload = {
      keyColumn: "id", // 주 키 컬럼 (여기에서는 id로 가정)
      keyValue: editCell.rowId.toString(),
      updates: {
        [editCell.column]: editCell.value,
      },
    };

    // SweetAlert2로 사용자 확인 요청
    Swal.fire({
      title: "수정 확인",
      text: `${editCell.column} 값을 "${editCell.value}"(으)로 수정하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await reqUpdateTableData(tableName, payload); // 수정 API 호출
          Swal.fire("성공", "데이터가 성공적으로 수정되었습니다.", "success");
          fetchData(currentPage); // 데이터 다시 불러오기
        } catch (error) {
          console.error("Error updating data:", error);
          Swal.fire("실패", "데이터 수정에 실패했습니다.", "error");
        } finally {
          setEditCell({ rowId: null, column: null, value: null }); // 수정 상태 초기화
        }
      } else {
        setEditCell({ rowId: null, column: null, value: null }); // 수정 취소 시 초기화
      }
    });
  };

  const totalPages = Math.ceil(total / 10);
  const paginationRange = 10;
  const paginationStart =
    Math.floor((currentPage - 1) / paginationRange) * paginationRange + 1;
  const paginationEnd = Math.min(
    paginationStart + paginationRange - 1,
    totalPages
  );
  // 미결제 조회
  const [transactionOrders, setTransactionOrders] = useState<any[]>([]);
  const [transactionProducts, setTransactionProducts] = useState<any[]>([]);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(true);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);

  useEffect(() => {
    // Fetch transaction data for orders
    const fetchTransactionOrders = async () => {
      setLoadingOrders(true);
      try {
        const response = await reqTransactionData();
        // API 응답의 data 내부에 실제 데이터가 존재
        const orders = response.data?.data || [];
        setTransactionOrders(orders);
      } catch (error) {
        console.error("Error fetching transaction orders:", error);
      } finally {
        setLoadingOrders(false);
      }
    };

    // Fetch transaction data for products
    const fetchTransactionProducts = async () => {
      setLoadingProducts(true);
      try {
        const response = await reqTransaction();
        // API 응답의 data 내부에 실제 데이터가 존재
        const products = response.data?.data || [];
        setTransactionProducts(products);
      } catch (error) {
        console.error("Error fetching transaction products:", error);
      } finally {
        setLoadingProducts(false);
      }
    };

    fetchTransactionOrders();
    fetchTransactionProducts();
  }, []);

  return (
    <div className="container mt-5">
      <div className="container mt-5">
        {/* Transaction Orders Section */}
        <StyledOrderListContainer>
          <StyledOrderTitle>Transaction ship</StyledOrderTitle>
          {loadingOrders ? (
            <p>Loading transaction orders...</p>
          ) : transactionOrders.length > 0 ? (
            <StyledOrderIdList>
              {transactionOrders.map((order, index) => (
                <li key={index}>
                  <strong>Order ID:</strong> {order.id}
                </li>
              ))}
              {transactionProducts.map((product, index) => (
                <li key={index}>
                  <strong>Product ID:</strong> {product.orderId}{" "}
                </li>
              ))}
            </StyledOrderIdList>
          ) : (
            <p>No transaction orders found.</p>
          )}
        </StyledOrderListContainer>
      </div>
      <h2 className="mb-4">테이블 데이터 조회</h2>

      {/* Search Controls */}
      <SearchControls className="mb-4">
        {/* 테이블 선택 드롭다운 */}
        <div className="control">
          <label htmlFor="tableSelect">테이블 선택</label>
          <select
            id="tableSelect"
            className="form-select"
            value={tableName}
            onChange={(e) => setTableName(e.target.value)} // 테이블 선택
          >
            {TABLE_NAMES.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        {/* 검색 필드 */}
        <div className="control">
          <label htmlFor="searchKey">검색 키</label>
          <input
            id="searchKey"
            type="text"
            className="form-control"
            placeholder="Search Key"
            value={searchKey}
            onChange={handleInputChange(setSearchKey)}
          />
        </div>
        <div className="control">
          <label htmlFor="searchWord">검색어</label>
          <input
            id="searchWord"
            type="text"
            className="form-control"
            placeholder="Search Word"
            value={searchWord}
            onChange={handleInputChange(setSearchWord)}
          />
        </div>
        <div className="control">
          <label htmlFor="orderColumn">정렬 컬럼</label>
          <select
            id="orderColumn"
            className="form-select"
            value={orderColumn}
            onChange={handleSelectChange(setOrderColumn)}
          >
            <option value="id">ID</option>
            <option value="name">Name</option>
            <option value="createdAt">Created At</option>
          </select>
        </div>
        <div className="control">
          <label htmlFor="orderWay">정렬 방식</label>
          <select
            id="orderWay"
            className="form-select"
            value={orderWay}
            onChange={handleSelectChange(setOrderWay)}
          >
            <option value="ASC">오름차순</option>
            <option value="DESC">내림차순</option>
          </select>
        </div>
        <div className="control">
          <label>&nbsp;</label>
          <button
            className="btn btn-primary w-100"
            onClick={handleSearch}
            disabled={loading}
          >
            {loading ? "검색 중..." : "검색"}
          </button>
        </div>
      </SearchControls>

      {/* 데이터 테이블 */}
      <StyledTableContainer>
        <StyledTable>
          <thead>
            <tr>
              {Object.keys(data[0] || {}).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                {Object.keys(row).map((key) => (
                  <td
                    key={key}
                    className="editable"
                    onClick={() => handleCellClick(row.id, key, row[key])}
                  >
                    {editCell.rowId === row.id && editCell.column === key ? (
                      <input
                        type="text"
                        value={editCell.value?.toString() || ""}
                        onChange={handleCellChange}
                        onBlur={handleCellBlur}
                        autoFocus
                      />
                    ) : (
                      row[key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </StyledTableContainer>

      {/* 페이지네이션 */}
      <PaginationWrapper>
        <ul>
          {paginationStart > 1 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange(paginationStart - 1)}
              >
                &laquo;
              </button>
            </li>
          )}
          {Array.from(
            { length: paginationEnd - paginationStart + 1 },
            (_, i) => paginationStart + i
          ).map((page) => (
            <li
              key={page}
              className={`page-item ${currentPage === page ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            </li>
          ))}
          {paginationEnd < totalPages && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange(paginationEnd + 1)}
              >
                &raquo;
              </button>
            </li>
          )}
        </ul>
      </PaginationWrapper>
    </div>
  );
};

export default FindTableDataPage;
