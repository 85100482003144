import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";

import { MEMBERSHIP, USE_CONDITION } from "../../common/constants";
import { MembershipInfo } from "../../interface/MembershipInfo";
import { User, initialUser } from "../../interface/User";
import { RootUserState } from "../../redux/store";
import { reqMyHaveCoupon, reqUseCoupon } from "../../requests/coupon";
import { reqGetUserById, reqUpdateUserMembership } from "../../requests/user";
import { reqGetMembership } from "../../requests/warehouse";

import CustomerPageTitle from "../../components/CustomerPageTitle";
import Icon from "../../components/Icon";
import CMembershipPayModal from "../../components/modal/CMembershipPayModal";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

// 기본 멤버십 정보
const baseMembershipInfo: MembershipInfo[] = [
  {
    id: 3,
    title: "Green",
    color: "var(--color-green)",
    subtitle: "Basic Membership",
    text: ["No storage cost for 30 days", "10% Custom order cost"],
    price: 0,
  },
  {
    id: 2,
    title: "Blue",
    color: "var(--color-main-blue)",
    subtitle: "3 Month Membership",
    text: [
      "No storage cost for 90 days",
      "3 free check content coupon",
      "25% Repack cost discount",
      "7% Custom order cost",
    ],
    price: 90,
  },
  {
    id: 1,
    title: "Red",
    color: "var(--color-main-red)",
    subtitle: "1 Year Membership",
    text: [
      "No storage cost for 365 days",
      "12 free check content coupon",
      "No Repack cost",
      "5% Custom order cost",
    ],
    price: 360,
  },
];

const CMembership = () => {
  const nav = useNavigate();
  const user = useSelector((state: RootUserState) => state.user);
  const [userData, setUserData] = useState<User>(initialUser);
  const [payModal, setPayModal] = useState<boolean>(false);
  const [selectedMembershipInfo, setSelectedMembershipInfo] =
    useState<MembershipInfo>();
  const [availableCouponList, setAvailableCouponList] = useState<any>();
  const [baseMembershipInfo, setBaseMembershipInfo] = useState<MembershipInfo[]>([]);

  const fetchUserData = async () => {
    const res = await reqGetUserById({ id: user.id });
    setUserData(res.data);
  };

  const fetchUserCoupons = async () => {
    const res = await reqMyHaveCoupon({ userId: user.id });
    const myCouponDataList = res.data.filter(
      (couponData: any) =>
        couponData.coupon.useCondition === USE_CONDITION["MEMBERSHIP"]
    );
    setAvailableCouponList(myCouponDataList);
    if (myCouponDataList.length > 0) {
      Toast.fire({
        icon: "success",
        title: "There are coupons you can use!",
      });
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchUserCoupons();
    fetchMembershipData();
  }, []);

  const fetchMembershipData = async () => {
    try {
      const response = await reqGetMembership(); // API 호출
      const apiData = response.data;

      const updatedMembershipInfo = [
        {
          id: 3,
          title: "Green",
          color: "var(--color-green)",
          subtitle: "Basic Membership",
          text: ["No storage cost for 30 days", "10% Custom order cost"],
          price: 0,
        },
        {
          id: 2,
          title: "Blue",
          color: "var(--color-main-blue)",
          subtitle: "3 Month Membership",
          text: [
            "No storage cost for 90 days",
            "3 free check content coupon",
            "25% Repack cost discount",
            "7% Custom order cost",
          ],
          price: parseFloat(
            apiData.find((item: any) => item.id === 1)?.signupFeeBlue || "90"
          ), // API 데이터에서 가져온 가격
        },
        {
          id: 1,
          title: "Red",
          color: "var(--color-main-red)",
          subtitle: "1 Year Membership",
          text: [
            "No storage cost for 365 days",
            "12 free check content coupon",
            "No Repack cost",
            "5% Custom order cost",
          ],
          price: parseFloat(
            apiData.find((item: any) => item.id === 1)?.signupFeeRed || "360"
          ), // API 데이터에서 가져온 가격
        },
      ];

      setBaseMembershipInfo(updatedMembershipInfo); // 기본 멤버십 정보 업데이트
    } catch (error) {
      console.error("Error fetching membership data:", error);
      Swal.fire("Error", "Failed to fetch membership data.", "error");
    }
  };

  const getAdjustedMembershipInfo = () => {
    const currentDate = new Date();

    const isDateInRange = (currentDate: Date) => {
      const year = currentDate.getFullYear();
      const startDate = new Date(year, 10, 25); // 11월 25일
      const endDate = new Date(year, 11, 31, 23, 59, 59, 999); // 12월 31일
      return currentDate >= startDate && currentDate <= endDate;
    };

    if (isDateInRange(currentDate)) {
      return baseMembershipInfo.map((membership) => {
        if (membership.id === 2) {
          return {
            ...membership,
            price: 63,
            text: [
              "No storage cost for 90 days",
              "3 free check content coupon",
              "25% Repack cost discount",
              "7% Custom order cost",
              "It's on sale from November 25th to December 31st!",
            ],
          };
        } else if (membership.id === 1) {
          return {
            ...membership,
            price: 180,
            text: [
              "No storage cost for 365 days",
              "12 free check content coupon",
              "No Repack cost",
              "5% Custom order cost",
              "It's on sale from November 25th to December 31st!",
            ],
          };
        } else {
          return {
            ...membership,
            text: ["No storage cost for 30 days", "10% Custom order cost"],
          };
        }
      });
    } else {
      return baseMembershipInfo;
    }
  };

  const membershipInfo = getAdjustedMembershipInfo();

  const handleUseCoupon = async (couponId: number) => {
    Swal.fire({
      title: "Do you want to use this coupon?",
      text: "",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const nextMembership =
            userData.membershipId === 1 ? 1 : userData.membershipId - 1;
          await reqUpdateUserMembership({
            id: user.id,
            membershipId: nextMembership,
          });
          await reqUseCoupon({
            couponLogId: couponId,
            krwDiscounted: 0,
            usdDiscounted: 0,
          });

          Swal.fire(
            "Congratulations on your membership promotion!",
            "",
            "success"
          );
          nav("/customer");
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const handleClickMembershipCard = (membership: MembershipInfo) => {
    if (membership.id !== 3) {
      setSelectedMembershipInfo(membership);
      setPayModal(true);
    }
  };

  const renderPrice = (membership: MembershipInfo) => {
    if (
      (membership.id === 2 || membership.id === 1) &&
      membership.price !==
        baseMembershipInfo.find((m) => m.id === membership.id)?.price
    ) {
      const originalPrice = baseMembershipInfo.find(
        (m) => m.id === membership.id
      )?.price;
      return (
        <div>
          <P className="original-price">{`${originalPrice}$`}</P>
          <P className="discounted-price">{`${membership.price}$`}</P>
        </div>
      );
    }
    return <P className="price">{`${membership.price}$`}</P>;
  };

  const Coupon = (coupon_: any) => {
    const coupon = coupon_.coupon;
    return (
      <CouponCard>
        <CouponInfo>
          <div className="good">
            {coupon.useCondition}
            {coupon.discountType === "PERCENT"
              ? `${coupon.percentDiscountPrice}% 할인`
              : `${coupon.usdDiscountPrice}$ 할인`}
          </div>
          <div className="name">{coupon.name}</div>
          <div className="limit">
            <div>
              {`More than ${coupon.krwLimitOfUsePrice?.toLocaleString(
                "ko-KR"
              )} ₩ available`}
            </div>
            <div>
              {`More than ${coupon.usdLimitOfUsePrice?.toLocaleString(
                "ko-KR"
              )} $ available`}
            </div>
          </div>
          <div className="due">{`유효기간: ${coupon.validityDate}`}</div>
        </CouponInfo>

        <CouponLogo className="logo">ontact</CouponLogo>
      </CouponCard>
    );
  };

  return (
    <>
      {payModal && (
        <CMembershipPayModal
          userData={userData}
          selectedMembership={selectedMembershipInfo as MembershipInfo}
          setPayModal={setPayModal}
        />
      )}

      <MembershipContainer>
        <CustomerPageTitle title="Membership" />

        <MembershipHeader>
          <P className="title">My Membership</P>
          <div className="icon-row">
            <IconWrapper color={MEMBERSHIP[userData.membershipId]}>
              <Icon icon="award" color="#fbf7f0" />
            </IconWrapper>
            {MEMBERSHIP[userData.membershipId]} Membership
          </div>
          <P className="date">
            {userData.membershipEndAt
              ? `Until ${new Date(userData.membershipEndAt).toLocaleString(
                  "ko-KR",
                  {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  }
                )}`
              : ""}
          </P>
        </MembershipHeader>

        <MembershipBody $isMobile={isMobile}>
          {membershipInfo.map((membership, index) => (
            <MembershipCard key={index} color={membership.color}>
              <div className="head">
                <P className="title">{membership.title}</P>
                <P className="subtitle">{membership.subtitle}</P>
              </div>
              <div className="body">
                <div className="text-box">
                  {membership.text.map((textItem, idx) => (
                    <P key={idx}>
                      <Icon icon="check-lg" fontSize="1.4rem" />
                      {textItem}
                    </P>
                  ))}
                </div>
                {renderPrice(membership)}
              </div>
              <div
                className="membership-footer"
                onClick={() => handleClickMembershipCard(membership)}
              >
                Join Membership
              </div>
            </MembershipCard>
          ))}
        </MembershipBody>

        <div style={{ marginTop: "2rem" }}>
          {availableCouponList &&
            availableCouponList.length > 0 &&
            availableCouponList.map((coupon: any, index: number) => (
              <div key={index} onClick={() => handleUseCoupon(coupon.id)}>
                <Coupon coupon={coupon.coupon} />
              </div>
            ))}
        </div>
      </MembershipContainer>
    </>
  );
};

const P = styled.p`
  margin: 0;

  &.original-price {
    text-decoration: line-through;
    color: gray;
    font-size: 1.2rem;
  }

  &.discounted-price {
    color: var(--color-main-red);
    font-weight: bold;
    font-size: 1.6rem;
  }

  &.price {
    font-weight: bold;
    font-size: 1.6rem;
  }
`;

const MembershipContainer = styled.div`
  padding: 1.6rem;
`;

const MembershipHeader = styled.div`
  border: 1px solid lightgray;
  border-radius: 1rem;
  padding: 1.4rem;
  margin-bottom: 1.6rem;

  .title {
    font-size: 2rem;
    font-weight: bold;
  }
  .icon-row {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    margin: 1rem 0;
  }
  .date {
    font-size: 1.2rem;
  }
`;

const MembershipBody = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  gap: 1rem;
`;

interface IconProps {
  color: string;
}

const MembershipCard = styled.div<IconProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  padding: 1.2rem;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 1rem 0.2rem 0 0.2rem;

  &:hover {
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.2), 0 5px 5px rgba(0, 0, 0, 0.2);
  }

  .head {
    border-bottom: 1px solid lightgray;
    padding-bottom: 1rem;
    margin-bottom: 1rem; /* Ensure consistent spacing between sections */
    color: ${(props) => props.color};

    .title {
      font-weight: bold;
      font-size: 1.6rem;
    }

    .subtitle {
      font-size: 1.2rem;
    }
  }

  .body {
    flex: 1; /* Allow the body to take up equal space */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem; /* Add spacing between body and footer */

    .text-box {
      margin-bottom: 1rem; /* Adjust spacing between list items */
    }

    .price {
      font-weight: bold;
      font-size: 1.6rem;
      padding-top: 1rem; /* Space above price for alignment */
    }
  }

  .membership-footer {
    margin-top: auto; /* Push footer to the bottom for alignment */
    text-align: center; /* Center-align footer text */
    padding-top: 1rem;
    border-top: 1px solid lightgray;
    color: ${(props) => props.color};
    font-weight: bold;
    cursor: pointer;
  }
`;

const IconWrapper = styled.div<IconProps>`
  background-color: ${(props) => {
    switch (props.color) {
      case "Red":
        return "var(--color-main-red)";
      case "Green":
        return "var(--color-green)";
      case "Blue":
        return "var(--color-main-blue)";
      default:
        return "#ffffff";
    }
  }};
  padding: 0.6rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.4rem;
`;

// Coupon 관련 스타일 및 컴포넌트
const CouponInfo = styled.div`
  flex: 7 1 0;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  .good {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--color-main-blue);
  }
  .name {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .due {
    color: var(--color-main-blue);
  }
`;

const CouponLogo = styled.div`
  background-color: var(--color-main-blue);
  color: var(--color-white);
  flex: 1 1 0;
  font-size: 1.8rem;
  font-weight: bold;
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CouponCard = styled.div`
  display: flex;
  border: 2px solid var(--color-main-blue);
  border-radius: 0.4rem;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover,
  &:active {
    background-color: #ffecdf;
  }

  &.un-available {
    border: 2px solid lightgray;
    &:hover,
    &:active {
      background-color: white;
    }
    * {
      color: lightgray !important;
    }
    .logo {
      background-color: lightgray;
      color: var(--color-white) !important;
    }
    .good,
    .due,
    .name {
      color: lightgray;
    }
    .limit div {
      color: var(--color-main-blue) !important;
    }
  }

  &.selected {
    background-color: #ffecdf;
  }
`;

export default CMembership;
