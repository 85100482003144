import axios from "axios";
import $ from "jquery";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import Swiper from "swiper";
import { formatDate } from "../../../common/format";
import { MODAL_TYPE } from "../../../components/modal/GlobalModal";
import ShopBeauty from "../../../components/recommandation/ShopBeauty";
import ShopFashion from "../../../components/recommandation/ShopFashion";
import ShopKpop from "../../../components/recommandation/ShopKpop";
import ShopManhwa from "../../../components/recommandation/ShopManhwa";
import ShopSnack from "../../../components/recommandation/ShopSnack";
import ShopStaionary from "../../../components/recommandation/ShopStationary";
import { Blog } from "../../../interface/Blog";
import { Review } from "../../../interface/Review";
import { modalOpen } from "../../../redux/modalSlice";
import { reqReviewForLandingPage } from "../../../requests/review";
import { reqShopProductCount } from "../../../requests/shop";
import { reqBlogList, reqRegisteredUserCount } from "../../../requests/user";
import "./css/aos.css";
import "./css/jquery.modal.min.css";
import "./css/media-query.css";
import "./css/muto_default.css";
import "./css/nice-select.css";
import "./css/style.css";
import "./css/swiper.min.css";

interface BlogPost extends Blog {
  user_liked?: boolean;
}

const Index = () => {
  const dispatch = useDispatch();

  //! useState Start

  // 랜딩 페이지에 보여지는 count section의 요소 데이터
  const [countItems, setCountItems] = useState<any>({
    userCount: 0,
    countryCount: 0,
    boxCount: 0,
  });

  // count 섹션의 뷰 tracker
  const [hasViewed, setHasViewed] = useState(false);

  // 대표로 선정된 블로그(이벤트)들
  const [blogList, setBlogList] = useState<BlogPost[]>([]);

  // 대표로 선정된 리뷰들
  const [reviewList, setReviewList] = useState<Review[]>([]);

  // 리뷰 페이징 처리
  const [currReviewPage, setCurrReviewPage] = useState<number>(1);
  const [reviewPageMetaData, setReviewPageMetaData] = useState<any>({
    reviewCount: 0,
    pageCount: 0,
  });

  // 인스타 feed 데이터 by API
  const [instaData, setInstaData] = useState<any[]>([]);

  //! useState End
  //! API fetch Start

  // 카운트 증가 위한 데이터
  // OK
  const fetchCountItmes = async () => {
    const userRes = await reqRegisteredUserCount();
    const boxRes = await reqShopProductCount();
    setCountItems({
      userCount: 4200 + userRes.data,
      countryCount: 195,
      boxCount: 50000 + 3 * boxRes.data,
    });
  };

  // 리뷰 데이터
  // OK
  const fetchReviews = async () => {
    const res = await reqReviewForLandingPage({ page: 1 });
    console.log(res.data);
    setReviewList(res.data.reviewList);

    const reviewCount = Number(res.data.reviewCount);
    let pageCount = Math.floor(reviewCount / 10);
    setReviewPageMetaData({
      reviewCount,
      pageCount,
    });
  };

  // 블로그(이벤트) 데이터
  // OK
  const fetchBlogs = async () => {
    const res = await reqBlogList();
    setBlogList(res.data?.eventList);
  };

  // 인스타 feed 데이터
  // OK
  // const fetchInstaData = async () => {
  //   const INSTAGRAM_USER_ID = 7584614351634394;
  //   const LONG_ACCESS_TOKEN =
  //     "IGQWRQNTNSNlZAma1VKNWFaNnpuaHgzSEZAiSl91cEVnMTg2MDdSSHhpRWkwbkxEYnJueEhUNGpMdUI1REw2bTM4TERubUxIRzhiRkZADc1N3RGk3elVLM2Q1azlJRVRlMU1VQ0VzemNxdTdpZAwZDZD";
  //   const { data } = await axios.get(`
  //   https://graph.instagram.com/${INSTAGRAM_USER_ID}/media?fields=id,media_type,media_url,permalink,thumbnail_url,username,caption&access_token=${LONG_ACCESS_TOKEN}
  //   `);
  //   setInstaData(data.data);
  // };

  //! API fetch End

  useEffect(() => {
    fetchCountItmes();
    fetchReviews();
    fetchBlogs();
    // fetchInstaData();
  }, []);

  // 리뷰 Swiper
  useEffect(() => {
    const swiper = new Swiper(".swiper-container", {
      slidesPerView: 1,
      breakpoints: {
        720: {
          slidesPerView: 3,
        },
      },
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, []);

  // Shopping Guide 뱃지
  useEffect(() => {
    $(function () {
      $(".tab_content").hide();
      $(".tab_content:first").show();

      $("ul.tabs li").click(function () {
        $("ul.tabs li").removeClass("active");
        $(this).addClass("active");
        $(".tab_content").hide();
        var activeTab = $(this).attr("rel");
        $("#" + activeTab).fadeIn();
      });

      // 모바일용
      $("#tab_select").change(function () {
        $(".tab_content").hide();
        var activeTab2 = $("#tab_select option:selected").val();
        $("#" + activeTab2).fadeIn();
      });
    });
  }, []);

  const maskLastName = (lastName: any) => {
    if (!lastName || lastName.length < 2) return lastName;
    return (
      "*".repeat(lastName.length - 1) + lastName.charAt(lastName.length - 1)
    );
  };

  const handleReviewModalClick = (review: Review) => {
    dispatch(
      modalOpen({
        modalType: MODAL_TYPE.LADING_REVIEW,
        title: "Review",
        payload: { data: review },
      })
    );
  };

  const handleBlogModalClick = (blog: Blog) => {
    dispatch(
      modalOpen({
        modalType: MODAL_TYPE.LADING_BLOG,
        title: "Blog",
        payload: { data: blog },
      })
    );
  };

  // 블로그 글자
  const truncateText = (text: any, maxLength: any) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const swiperNext = () => {
    const swiperInstance = (document.querySelector(".swiper-container") as any)
      ?.swiper;
    swiperInstance?.slideNext();
  };

  const swiperPrev = () => {
    const swiperInstance = (document.querySelector(".swiper-container") as any)
      ?.swiper;
    swiperInstance?.slidePrev();
  };

  return (
    <LandingWrapper>
      <div id="landing-page">
        {/* 배너 */}
        {/* TODO: 카루셀 적용 (난이도 이슈로 잠시 중단) */}
        <div className="main_banner">
          {isMobile ? (
            <div className="container_wrap po_rel">
              <div className="mobile_text_box">
                <h2>
                  Your Global Gateway
                  <br />
                  to Korean Goods
                </h2>
                <p>Korean shopping has never been this easy!</p>
              </div>

              <div className="mobile_img">
                <img
                  src="/landing/images/main_img_blue.png"
                  alt=""
                  style={{ width: "20rem" }}
                />
              </div>
            </div>
          ) : (
            <div className="container_wrap po_rel">
              <div className="text_box">
                <h2>
                  Your Global Gateway
                  <br />
                  to Korean Goods
                </h2>
                <p>Korean shopping has never been this easy!</p>
              </div>

              <div className="img">
                <img
                  src="/landing/images/main_img_blue.png"
                  alt=""
                  style={{ width: "32rem" }}
                />
              </div>
            </div>
          )}
        </div>

        {/* Our services 섹션 */}
        <div className="service_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="part_title_wrap">
              <h3 className="part_title">Our services</h3>
            </div>
            <ul>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l"
                  src="/landing/images/img_1.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <h4>Korean Address</h4>
                  <p>
                    Take control of your Korean shopping experience!
                    <br />
                    By utilizing a Korean address, you can order directly from
                    local suppliers. We'll store your packages until you're
                    ready for shipping, allowing you to shop at your own pace.
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l"
                  src="/landing/images/img_2.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <h4>Custom Orders</h4>
                  <p>
                    Experience a hassle-free shopping journey with us.
                    <br />
                    We take care of everything from purchasing your desired
                    items from Korean suppliers to snapping pictures of your
                    orders and storing them safely until you're ready for
                    shipping!
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l"
                  src="/landing/images/img_3.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <h4>International Shipping</h4>
                  <p>
                    Experience the streamlined efficiency of our shipping
                    service.
                    <br />
                    Request shipping, and we'll consolidate your items for
                    maximum convenience, delivering your package directly to
                    your doorstep.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Counter 섹션 */}
        <div className="count_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="part_title_wrap">
              <h3 className="part_title">
                Our Services connect fans to the world!
              </h3>
            </div>
            <div>
              <VisibilitySensor partialVisibility>
                {({ isVisible }: any) => {
                  if (isVisible && !hasViewed) {
                    setHasViewed(true);
                  }
                  return <div style={{ visibility: "hidden" }}>d</div>;
                }}
              </VisibilitySensor>
            </div>

            <VisibilitySensor partialVisibility>
              {({ isVisible }: any) => {
                if (isVisible && !hasViewed) {
                  setHasViewed(true);
                }
                return (
                  <ul>
                    {hasViewed ? (
                      <>
                        <li data-aos="fade-up">
                          <span className="count">
                            <CountUp
                              start={0}
                              end={countItems.userCount}
                              duration={1}
                            />
                          </span>
                          <p>Client</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="100">
                          <span className="count">
                            <CountUp
                              start={0}
                              end={countItems.countryCount}
                              duration={1}
                            />
                          </span>
                          <p>Countries</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="200">
                          <span className="count">
                            <CountUp
                              start={0}
                              end={countItems.boxCount}
                              duration={1}
                            />
                          </span>
                          <p>Shipped</p>
                        </li>
                      </>
                    ) : null}
                  </ul>
                );
              }}
            </VisibilitySensor>
          </div>
        </div>

        {/* Feature 섹션 */}
        <div className="features_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="part_title_wrap">
              <img
                className="symbol"
                src="/landing/images/head_logo.png"
                alt=""
              />
              <h3 className="part_title" style={{ color: "#4B84DA" }}>
                Why choose Ontact Korea?
              </h3>
              <p>
                Discover Ontact Korea—your gateway to Korean products! As a
                leading Korean warehouse company, we cater to Korean product
                lovers by offering convenient shopping and storage solutions
                while minimizing shipping costs. We specialize in K-pop items
                like albums, lightsticks, and various merchandise and ship
                worldwide. Uncover the best of Korea with Ontact Korea!
              </p>
            </div>
            <div className="part_title_wrap mar_t_100">
              <h3 className="part_title">Features & Highlights</h3>
            </div>
            <ul>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l img"
                  src="/landing/images/img_4.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <span>Point 1</span>
                  <h4>Package management made simple</h4>
                  <p>
                    Effortlessly track arrivals, input package details, and
                    request content checks and shipping.
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l img"
                  src="/landing/images/img_9.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <span>Point 2</span>
                  <h4>Fast Ontact team assistance</h4>
                  <p>
                    Our customer service delivers speedy solutions, Monday to
                    Friday.
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l img"
                  src="/landing/images/img_6.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <span>Point 3</span>
                  <h4>Easy checkout</h4>
                  <p>
                    Multiple payment options for your convenience in USD and
                    KRW.
                  </p>
                  <div className="card_list">
                    <img src="/landing/images/card_1.png" alt="" />
                    <img src="/landing/images/card_2.png" alt="" />
                    <img src="/landing/images/card_3.png" alt="" />
                    <img src="/landing/images/card_4.png" alt="" />
                    <img src="/landing/images/card_5.png" alt="" />
                    <img src="/landing/images/card_6.png" alt="" />
                  </div>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l img"
                  src="/landing/images/img_10.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <span>Point 4</span>
                  <h4>Free package arrival picture</h4>
                  <p>
                    Experience peace of mind with our free package arrival
                    picture feature.
                    <br />
                    Receive a photo for every package so you can see its
                    condition without worry.
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l img"
                  src="/landing/images/img_8.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <span>Point 5</span>
                  <h4>In real-time status update</h4>
                  <p>
                    Stay informed with real-time status updates for each order,
                    ensuring you always know what you've received.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* 멤버십 섹션 */}
        {/* TODO: 멤버십 클릭 하이퍼링크 수정 (어디로?) */}
        <div className="membership_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="part_title_wrap">
              <h3 className="part_title">
                Unlock maximum savings potential
                <br />
                with our exclusive warehouse memberships!
              </h3>
            </div>
            <ul className="after grade">
              <li className="list green" data-aos="fade-up">
                <h4>Green</h4>
                <h5>Basic Membership</h5>
                <ul className="info">
                  <li>· 30 days free storage per package</li>
                  <li>· 10% Custom order fee</li>
                </ul>
                <a href="">Detailed info</a>
              </li>
              <li className="list blue" data-aos="fade-up" data-aos-delay="100">
                <h4>Blue</h4>
                <h5>3 months Membership</h5>
                <ul className="info">
                  <li>· No storage fee for 90 days</li>
                  <li>· 25% Repack fee discount</li>
                </ul>
                <a href="">Detailed info</a>
                <img src="/landing/images/most-pop.png" alt="" />
              </li>
              <li className="list red" data-aos="fade-up" data-aos-delay="200">
                <h4>Red</h4>
                <h5>1 year Membership</h5>
                <ul className="info">
                  <li>· No storage fee for 365 days</li>
                  <li>· 100% Repack fee discount</li>
                </ul>
                <a href="">Detailed info</a>
              </li>
            </ul>
          </div>
        </div>

        {/* 리뷰 섹션 */}
        <div className="review_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="part_title_wrap">
              <h3 className="part_title">Reviews</h3>
              <p>Thankful review from Ontact Korea members.</p>
            </div>
            <div className="swiper-container" data-aos="fade-up">
              <div className="swiper-wrapper">
                {reviewList.map((review: Review) => {
                  const userInfo = review?.user;
                  const userName = ((userInfo?.firstName as string) +
                    " " +
                    maskLastName(userInfo?.lastName)) as string;
                  return (
                    <div
                      className="swiper-slide list"
                      onClick={() => handleReviewModalClick(review)}
                    >
                      <h4 style={{ color: "var(--color-black)" }}>
                        {userName}
                      </h4>
                      <ul className="star">
                        {[1, 2, 3, 4, 5].map((_, index) => {
                          return index <= Number(review.rating) ? (
                            <li className="on"></li>
                          ) : (
                            <li className=""></li>
                          );
                        })}
                      </ul>
                      {review.photoUrls ? (
                        <div className="thumb">
                          <img
                            src={`https://kr.object.ncloudstorage.com/ontact-bucket/${review.photoUrls}`}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="thumb no-img">No Image</div>
                      )}
                      <div className="content">{review.content}</div>
                    </div>
                  );
                })}
              </div>
              <button
                className="swiper-button-prev"
                onClick={swiperPrev}
              ></button>
              <button
                className="swiper-button-next"
                onClick={swiperNext}
              ></button>
            </div>
          </div>
        </div>

        {/* Shopping Guide 섹션 */}
        <div className="shop_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="part_title_wrap">
              <h3 className="part_title">Shopping Guide</h3>
              <p>Don't know where to shop ? Check out our team recs.</p>
            </div>

            <ul className="tabs hide_1080">
              <li className="active" rel="tab1">
                K-POP
              </li>
              <li rel="tab2">Beauty</li>
              <li rel="tab3">Fashion</li>
              <li rel="tab4">Animation</li>
              <li rel="tab5">Stationary</li>
              <li rel="tab6">Snack</li>
            </ul>
            <select className="show_1080 hide wide" name="" id="tab_select">
              <option value="tab1">K-POP</option>
              <option value="tab2">Beauty</option>
              <option value="tab3">Fashion</option>
              <option value="tab4">Animation</option>
              <option value="tab5">Stationary</option>
              <option value="tab6">Snack</option>
            </select>
            <div className="tab_container">
              <div id="tab1" className="tab_content">
                <ul>
                  <ShopKpop />
                </ul>
              </div>
              <div id="tab2" className="tab_content">
                <ul>
                  <ShopBeauty />
                </ul>
              </div>
              <div id="tab3" className="tab_content">
                <ul>
                  <ShopFashion />
                </ul>
              </div>
              <div id="tab4" className="tab_content">
                <ul>
                  <ShopManhwa />
                </ul>
              </div>
              <div id="tab5" className="tab_content">
                <ul>
                  <ShopStaionary />
                </ul>
              </div>
              <div id="tab6" className="tab_content">
                <ul>
                  <ShopSnack />
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* 인스타 섹션 */}
        {/* <div className="insta_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="part_title_wrap">
              <h3 className="part_title">Instagram</h3>
            </div>
            <ul className="after" data-aos="fade-up">
              {instaData
                .filter((data: any) => data.thumbnail_url !== undefined)
                .slice(0, 8)
                .map((data: any, index: number) => {
                  return (
                    <li>
                      <a
                        href={data.permalink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={data.thumbnail_url} alt="" />
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div> */}

        {/* 블로그 섹션 */}
        <div className="blog_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="part_title_wrap">
              <h3 className="part_title">Blog</h3>
            </div>
            <ul className="after" data-aos="fade-up">
              {blogList.slice(0, 3).map((blog) => (
                <li key={blog.id} onClick={() => handleBlogModalClick(blog)}>
                  <a href="#!">
                    <div className="thumb">
                      <img
                        src={`https://kr.object.ncloudstorage.com/ontact-bucket/${blog.imageUrl}`}
                        alt="blog-post"
                      />
                    </div>
                    <div className="content">
                      <h4>{blog.title}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: truncateText(blog.content, 100),
                        }}
                      ></p>
                      <div className="info after">
                        <div className="date float_l">
                          {formatDate(blog.createdAt)}
                        </div>
                        <div className="like float_r">
                          <img src="/landing/images/icon_like.png" alt="" />
                          <span>{blog.like}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* 쿠폰 지급 섹션 */}
        {/* TODO: 필요 쿠폰 링크 적용 */}
        <div className="join_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="content">
              <h3>Join today get $7 coupon!</h3>
              <p>Join the Ontact Korea family today and save $7 on shipping</p>
              <a href="/signup" className="join_btn" data-aos="fade-up">
                Sign up now
              </a>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer>
          <div className="container_wrap after">
            <div className="part">
              <h3>Ontact Korea</h3>
              <p>
                CEO : SON JU KYUNG &nbsp;|&nbsp; Business Number : 420-71-00335
                <br />
                1F, 36, Jwasuyeong-ro 210beon-gil, Suyeong-gu, Busan, Republic
                of Korea, 48213
              </p>
              <h3>
                <a
                  href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit#heading=h.r5gfdkjr887q"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
              </h3>

              <p>Privacy Policy</p>
            </div>
            <div className="part">
              <h3>Contact Us</h3>
              <p>
                Tel : 051-751-9020
                <br />
                Email : ontactkorea@gmail.com
                <br />
                CS : +82 10-2716-9020
                <br />
                Mon-Fri : 10AM ~ 18PM (excluded weekend, holiday)
              </p>
            </div>
          </div>
        </footer>

        {/* Scroller */}
        <a
          href="#"
          className="scroll-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
      </div>
    </LandingWrapper>
  );
};

const LandingWrapper = styled.div`
  margin-top: 80px;
  #landing-page > div {
    display: flex;
    justify-content: center;
  }
`;

export default Index;
