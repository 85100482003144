import { AxiosManager } from "../axiosManager";

export function reqCreateCoupon(payload: any) {
  return AxiosManager.post("coupon/create", payload);
}

export function reqCouponList() {
  return AxiosManager.get("coupon/list");
}

export function reqAllCouponList() {
  return AxiosManager.get("coupon/logs");
}

export function reqAllPoint() {
  return AxiosManager.get("point-logs");
}

export function reqEachCouponList() {
  return AxiosManager.get("coupon/list/each");
}

export function reqModifyCoupon(payload: any) {
  return AxiosManager.put("coupon/modify", payload);
}

export const reqDeleteCoupon = async (payload: any) => {
  const result = await AxiosManager.get("coupon/delete", {
    params: payload,
  });
  return result;
};

export function reqSendCoupon(payload: any) {
  return AxiosManager.post("coupon/send", payload);
}

export const reqGetCouponLog = async (payload: any) => {
  const result = await AxiosManager.get("coupon/log", {
    params: payload,
  });
  return result;
};

export const reqMyHaveCoupon = async (payload: any) => {
  const result = await AxiosManager.get("coupon/my-have", {
    params: payload,
  });
  return result;
};

export async function reqUseCoupon(payload: any) {
  return await AxiosManager.get("coupon/use", {
    params: payload,
  });
}

export async function reqRestoreCoupon(payload: any) {
  return await AxiosManager.put("coupon/restore", {
    params: payload,
  });
}

export const reqResetCouponByOrderId = async (orderId: any) => {
  return await AxiosManager.put("coupon/reset-by-order-id", {
    params: { orderId },
  });
};

export async function reqCouponByCode(payload: any) {
  return await AxiosManager.get("coupon/code", {
    params: payload,
  });
}

export async function reqCouponDuplicateCheck(payload: any) {
  return await AxiosManager.get("coupon/duplicate-check", {
    params: payload,
  });
}

export const reqSingleCouponLog = async (payload: any) => {
  const result = await AxiosManager.get("coupon/single-log", {
    params: payload,
  });
  return result;
};

// 쿠폰 지급 요청 추가
export const reqIssueCheckContentsCoupon = async (payload: {
  userId: string;
  membershipId: number;
  count: number;
}) => {
  return AxiosManager.post("coupon/issue-check-contents", payload);
};

// 쿠폰 지급 API - 사용자가 해시 코드를 입력하여 쿠폰을 지급받는 요청
export const reqRedeemCoupon = async (payload: { userId: string; hashCode: string }) => {
  return await AxiosManager.post("coupon/redeem", payload);
};

// 쿠폰 해시코드 수정 요청
export const reqUpdateCouponHashCode = async (couponId: number, newHashCode: string) => {
  return await AxiosManager.put(`coupon/${couponId}/hash-code`, { newHashCode });
};